<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-list
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-card>
        <ion-item lines="none" class="ion-no-padding">
          <ion-card-header>
            <ion-card-title>
              {{ item.subject.name }} -
              {{ item.chapter ? item.chapter.name : "N/A" }}</ion-card-title
            >
          </ion-card-header>
        </ion-item>
        <ion-card-header style="padding-top: 0">
          <ion-card-subtitle>
            <ion-text color="primary"
              >Class {{ item.section_standard.standard.name }}-{{
                item.section_standard.section.name
              }}
            </ion-text></ion-card-subtitle
          >
        </ion-card-header>
        <ion-card-content>
          {{ item.description }}
          <br />
          <br />
          Created by: {{ item.creator.user_detail.name }}
          <br />
          {{ new Date(item.homework_to_date).toDateString() }} to
          {{ new Date(item.homework_to_date).toDateString() }}
        </ion-card-content>
      </ion-card>
    </ion-list>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No homework found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonText,
} from "@ionic/vue";

import {
  trashOutline,
  trashSharp,
  createOutline,
  createSharp,
} from "ionicons/icons";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonText,
  },
  data() {
    return {
      createOutline,
      createSharp,
      trashOutline,
      trashSharp,
    };
  },
};
</script>
